<template>
  <b-overlay :show="loadingIndicator || $apollo.loading"
    rounded="sm" variant="dark" class="position-relative">
    <b-navbar class="header" toggleable="md" type="dark" fixed="top">
      <b-navbar-brand id="logo" to="/" class="m-0 p-0 mr-4 fr-display-block">
        <img class="m-3" src="@/assets/logo.png" alt="Webmag Logo" />
      </b-navbar-brand>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto m-0 p-0 mr-4">
        <b-row>
          <p class="text-center mt-4 mr-1">
            <small class="login">{{ $t('register.alreadyRegistered') }}</small>
          </p>
          <b-button class="green-button-transparent border-0 m-3">
            <b-link to="/webmag-login">{{ $t('login.login') }}</b-link>
          </b-button>
        </b-row>
      </b-navbar-nav>
    </b-navbar>
    <b-container class="b-card-forms">
      <b-row class="my-4" align-h="center" p-0>
        <b-col align-self="center" cols="12" sm="12" lg="12">
          <b-card v-if="!loggedIn && !showThankYouText">
            <b-row class="ml-0 mr-0">
              <b-col cols="12" sm="12" lg="6" class="p-4 bg-white d-flex align-items-center">
                <div v-if="!userNotConfirmed" class="m-4">
                  <h3> {{$t('register.start')}} </h3>
                  <h5> {{$t('register.desc1')}} </h5>
                  <b-form-valid-feedback>
                    <div class="test">
                      {{ $t('login.verificationCodeSendMessage') }}
                    </div>
                  </b-form-valid-feedback>
                  <b-alert :show="userWasLoggedOut" variant="warning">
                    {{ $t('register.userLoggedOutMsg') }}
                  </b-alert>
                  <b-form @submit.stop.prevent="onSubmit">
                    <b-form-group id="name-group">
                      <b-form-input
                        id="name"
                        name="name"
                        type="text"
                        v-model.trim="$v.form.name.$model"
                        :placeholder="$t('register.placeholderName')"
                        aria-describedby="name-live-feedback"
                        :state="validateState('name')"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="telephone-group">
                      <b-form-input
                        id="telephone"
                        name="telephone"
                        type="tel"
                        v-model.trim="$v.form.telephone.$model"
                        :placeholder="$t('register.placeholderTelephone')"
                        aria-describedby="telephone-live-feedback"
                        :state="validateState('telephone')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="telephone-live-feedback">
                        <div class="error" v-if="!$v.form.telephone.required">
                          {{ $t('register.requiredTelephone') }}</div>
                        <div class="error"
                        v-if="!$v.form.telephone.numeric">
                        {{ $t('register.invalidTelephone') }}</div>
                        <div class="error"
                        v-if="!$v.form.telephone.minLength">
                          {{ $t('register.telephoneMinLength',
                          { telephoneLength: $v.form.telephone.$params.minLength.min }) }}
                        </div>
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="email-group">
                      <b-form-input
                        id="email-input"
                        name="email-input"
                        type="email"
                        @input="resetDuplicateEmail"
                        :placeholder="$t('login.placeholderEmail')"
                        v-model.trim="$v.form.email.$model"
                        :state="validateState('email')"
                        aria-describedby="email-live-feedback"
                        :disabled="isDisabled"
                      ></b-form-input>
                      <b-form-invalid-feedback id="email-live-feedback">
                        <div class="error"
                        v-if="!$v.form.email.email">{{ $t('login.invalidEmail') }}</div>
                        <div class="error"
                        v-if="!$v.form.email.b2bEmailValidation">
                        {{ $t('register.notBusinessEmail') }}</div>
                      </b-form-invalid-feedback>
                      <div class="error invalid-feedback d-block"
                      v-if="duplicateEmail">{{ $t('register.emailAlreadyExists') }}</div>
                    </b-form-group>
                    <b-form-group id="password-group" class="position-relative">
                      <b-form-input
                        id="password-input"
                        name="password-input"
                        :placeholder="$t('login.placeholderPassword')"
                        :type="showPassword ? 'text' : 'password'"
                        v-model.trim="$v.form.password.$model"
                        :state="validateState('password')"
                        @input="resetInvalidPassword"
                        aria-describedby="password-live-feedback"
                      ></b-form-input>
                      <b-icon
                        v-if="!showPassword"
                        icon="eye-slash"
                        class="position-absolute password-eye"
                        aria-hidden="true"
                        scale="1"
                        @click="togglePasswordVisibility(true)"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="eye"
                        class="position-absolute password-eye"
                        aria-hidden="true"
                        scale="1"
                        @click="togglePasswordVisibility(false)"
                      ></b-icon>
                      <b-form-invalid-feedback id="password-live-feedback">
                        <div class="error"
                        v-if="!$v.form.password.required">
                        {{ $t('register.requiredPassword') }}</div>
                        <div class="error"
                        v-if="!$v.form.password.minLength">
                          {{ $t('register.passwordMinLength',
                          { pwLength: $v.form.password.$params.minLength.min }) }}
                        </div>
                        <div class="error"
                        v-if="!$v.form.password.letterValidation">
                        {{ $t('register.letterRequired') }}</div>
                        <div class="error"
                        v-if="!$v.form.password.numberValidation">
                        {{ $t('register.numberRequired') }}</div>
                        <div class="error"
                        v-if="!$v.form.password.specialCharValidation">
                        {{ $t('register.specialCharRequired') }}</div>
                      </b-form-invalid-feedback>
                      <div class="error invalid-feedback d-block"
                      v-if="invalidPassword">
                      {{ $t('register.invalidPasswordException') }}</div>
                    </b-form-group>
                    <b-form-group id="password-confirm-group" class="position-relative">
                      <b-form-input
                        id="password-confirm-input"
                        name="password-confirm-input"
                        :placeholder="$t('register.passwordConfirm')"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        v-model.trim="$v.form.passwordConfirm.$model"
                        :state="validateState('passwordConfirm')"
                        aria-describedby="password-confirm-live-feedback"
                      ></b-form-input>
                      <b-icon
                        v-if="!showConfirmPassword"
                        icon="eye-slash"
                        class="position-absolute password-eye"
                        aria-hidden="true"
                        scale="1"
                        @click="togglePasswordConfirmVisibility(true)"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="eye"
                        class="position-absolute password-eye"
                        aria-hidden="true"
                        scale="1"
                        @click="togglePasswordConfirmVisibility(false)"
                      ></b-icon>
                      <b-form-invalid-feedback id="password-confirm-live-feedback">
                        {{ $t('register.passwordMismatch') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <div class="mb-3">
                      <b-form-checkbox
                        id="confirmation"
                        v-model.trim="$v.form.confirmation.$model"
                        name="confirmation"
                        value="accepted"
                        :unchecked-value="null"
                        :state="validateState('confirmation')"
                        aria-describedby="confirmation-live-feedback"
                      >
                        <span v-html="$t('register.confirmCheckbox')"></span>
                      </b-form-checkbox>
                      <b-form-invalid-feedback id="confirmation-live-feedback">
                        Please select two</b-form-invalid-feedback>
                    </div>
                    <b-button block class="p-3" type="submit" variant="success">
                      {{ $t('register.button') }}
                    </b-button>
                    <p class="text-center mt-4">
                      <small class="password-reset">{{ $t('login.forgotPassword') }}</small>
                      <small>
                        <b-link to="/webmag-password-reset">{{ $t('login.resetPassword') }}</b-link>
                      </small>
                    </p>
                  </b-form>
                </div>
                <verification-code-msg
                  v-else
                  class="m-4"
                  :email="form.email"
                  @verification-success="verificationNumberEntered"
                  @show-loader="showLoader"
                ></verification-code-msg>
              </b-col>
              <b-col cols="12" sm="12" lg="6" class="imageColumn text-center flex-column p-4">
                <div class="align-content-center">
                  <img class="sigup m-auto rounded-0"
                  src="@/assets/webmag-signup.png" alt="Signup" />
                  <h4 class="mt-4 mb-3"> {{$t('register.desc2')}} </h4>
                  <b-row>
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/iveco.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/businesses.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/asd.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/sparkasse.svg" alt="Signup" />
                  </b-row>
                  <b-row>
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/cnhi.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/prognos.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/haufe-akademie.svg" alt="Signup" />
                    <img class="businesses m-auto rounded-0"
                    src="@/assets/svg/navos.svg" alt="Signup" />
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-else body-class="text-center">
            <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo" />
            <div>
              <h5 class="mb-4">{{ $t('register.thankYouTitle') }}</h5>
              <p v-html="$t('register.thankYouMessage')"></p>
              <p><strong>{{ $t('register.team') }}</strong></p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { Auth } from '@aws-amplify/auth';
import Login from '@/mixins/login';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import emailBlackList from '@/defaultData/nonBusinessEmailBlacklist';
import { validationMixin } from 'vuelidate';
import {
  required, email, numeric, minLength, sameAs,
} from 'vuelidate/lib/validators';

const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
const specialCharValidation = (value) => specialChars.test(value);
const numberValidation = (value) => /\d/.test(value);
const letterValidation = (value) => /[a-z]/.test(value);
// const b2bEmailValidation = (value) => {
//   // if skipBlacklist is true, we skip the blacklist check
//   if (this.skipBlacklist) {
//     return true;
//   }
//   if (!value) {
//     return true;
//   }
//   return !emailBlackList.some((element) => value.includes(element));
// };

export default {
  name: 'SignUp',
  mixins: [Login, validationMixin, SetLanguageToBrowserLanguage],
  components: {
    VerificationCodeMsg: () => import('@/components/messages/VerificationCode.vue'),
  },
  data: () => ({
    awsError: null,
    loadingIndicator: true,
    loggedIn: false,
    isDisabled: false,
    skipBlacklist: false,
    showEmailError: false,
    form: {
      name: null,
      telephone: null,
      email: null,
      password: null,
      passwordConfirm: null,
      confirmation: null,
    },
    duplicateEmail: false,
    invalidPassword: false,
    showPassword: false,
    showConfirmPassword: false,
    showThankYouText: false,
    userNotConfirmed: false,
    userWasLoggedOut: false,
  }),
  validations() {
    const b2bEmailValidation = (value) => {
      if (this.skipBlacklist) {
        return true;
      }
      if (!value) {
        return true;
      }
      return !emailBlackList.some((element) => value.includes(element));
    };

    return {
      form: {
        name: { },
        telephone: {
          required,
          numeric,
          minLength: minLength(8),
        },
        email: {
          required,
          email,
          b2bEmailValidation,
        },
        password: {
          required,
          minLength: minLength(8),
          specialCharValidation,
          letterValidation,
          numberValidation,
        },
        passwordConfirm: {
          required,
          sameAsPassword: sameAs('password'),
        },
        confirmation: {
          required,
        },
      },
    };
  },
  created() {
    this.setLanguageToBrowserLanguage();
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'registration-page',
      },
    });
  },
  async mounted() {
    if (this.$route.query?.email) {
      this.form.email = this.$route.query.email;
      this.isDisabled = true;
    }
    if (this.$route.query?.blacklist) {
      this.skipBlacklist = true;
    }
    if (this.$route.query?.name) {
      this.form.name = this.$route.query.name;
    }
    try {
      const awsUser = await Auth.currentAuthenticatedUser();
      if (awsUser) {
        await Auth.signOut({ global: true });
        this.userWasLoggedOut = true;
      }
    } catch (error) {
      console.error(error);
    }
    this.loadingIndicator = false;
    this.$emit('useCrisp');
    this.addGTM();
  },
  methods: {
    addGTM() {
      const gtmConfig = 'id=GTM-PMGM4Q6';
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://www.googletagmanager.com/gtm.js?${gtmConfig}`;
      s.setAttribute('async', '');
      document.head.appendChild(s);
      console.log('s', s);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async verificationNumberEntered(status) {
      if (status) {
        try {
          const awsUser = await Auth.signIn(this.form.email, this.form.password);
          await this.awsUserConfirmedAndEmailValidated(awsUser);
          const d = new Date();
          let lang = window.navigator.languages ? window.navigator.languages[0] : null;
          lang = lang || window.navigator.language
          || window.navigator.browserLanguage || window.navigator.userLanguage;
          if (lang.indexOf('-') !== -1) [lang] = lang.split('-');
          if (lang.indexOf('_') !== -1) [lang] = lang.split('_');
          if (window.$crisp) {
            window.$crisp.push(['set', 'user:email', [awsUser.username]]);
            window.$crisp.push(['set', 'session:event', ['user:register']]);
            window.$crisp.push(['set', 'session:data', [[['user-registered', d.toTimeString()]]]]);
            const hasuraUserInfo = JSON.parse(awsUser.signInUserSession.getIdToken().payload['https://hasura.io/jwt/claims']);
            if (hasuraUserInfo['x-hasura-user-exists'] === 'true') {
              window.$crisp.push(['set', 'session:segments', [[lang, 'customer', 'team-invited']]]);
            } else {
              window.$crisp.push(['set', 'session:segments', [[lang, 'customer']]]);
            }
          }
        } catch (error) {
          await this.$router.push('/webmag-logout').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
      }
    },
    async onSubmit() {
      this.userWasLoggedOut = false;
      this.loadingIndicator = true;
      this.userNotConfirmed = false;
      // logout first so we don't get problems
      await Auth.signOut({ global: true });
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.loadingIndicator = false;
        return;
      }
      try {
        const emailOfUser = this.form.email.toLowerCase().trim();
        const { user: awsUser } = await Auth.signUp({
          username: emailOfUser,
          password: this.form.password,
          attributes: {
            email: emailOfUser,
            name: this.form.name,
            // adding the telephone number to the user in the middle_name field, because of lazyness
            middle_name: this.form.telephone,
          },
        });
        if (awsUser) {
          this.userNotConfirmed = true;
        }
        this.loadingIndicator = false;
      } catch (error) {
        if (error && error.code === 'UsernameExistsException') {
          this.duplicateEmail = true;
        }
        if (error && error.code === 'InvalidPasswordException') {
          this.invalidPassword = true;
        }
      }
      this.loadingIndicator = false;
    },
    showLoader(value) {
      this.loadingIndicator = !!(value);
    },
    resetDuplicateEmail() {
      this.duplicateEmail = false;
      this.showEmailError = false;
    },
    resetInvalidPassword() {
      this.invalidPassword = false;
    },
    togglePasswordVisibility(value) {
      this.showPassword = !!value;
    },
    togglePasswordConfirmVisibility(value) {
      this.showConfirmPassword = !!value;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 33px;
  display: block;
}

.navbar {
  position: relative;

  .navbar-nav {
    height: 65px;
  }
}

.btn-link {
  color: #fff !important;
}

.card-body {
  padding: 35px;
}

small {
  display: block;
}

input[disabled="disabled"] {
  background-color: grey;
  cursor: not-allowed;
  color: #fff;
}

.password-eye {
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.is-valid ~ .password-eye,
.is-invalid ~ .password-eye {
  right: 35px;
}

.sigup {
  width: 95%;
}

.businesses {
  width: 15%;
}

.imageColumn {
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
}

h5 {
  margin-bottom: 35px;
}

h3, h4 {
  font-weight: bold;
}

h3, h4, h5, h6, .password-reset, span {
  color: black;
}

.login, a {
  color: white;
}

.card-body {
  padding: 0;
  background-color: #f2f2f2;
}
</style>
