var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-overlay",
    {
      staticClass: "position-relative",
      attrs: {
        show: _vm.loadingIndicator || _vm.$apollo.loading,
        rounded: "sm",
        variant: "dark"
      }
    },
    [
      _c(
        "b-navbar",
        {
          staticClass: "header",
          attrs: { toggleable: "md", type: "dark", fixed: "top" }
        },
        [
          _c(
            "b-navbar-brand",
            {
              staticClass: "m-0 p-0 mr-4 fr-display-block",
              attrs: { id: "logo", to: "/" }
            },
            [
              _c("img", {
                staticClass: "m-3",
                attrs: { src: require("@/assets/logo.png"), alt: "Webmag Logo" }
              })
            ]
          ),
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto m-0 p-0 mr-4" },
            [
              _c(
                "b-row",
                [
                  _c("p", { staticClass: "text-center mt-4 mr-1" }, [
                    _c("small", { staticClass: "login" }, [
                      _vm._v(_vm._s(_vm.$t("register.alreadyRegistered")))
                    ])
                  ]),
                  _c(
                    "b-button",
                    { staticClass: "green-button-transparent border-0 m-3" },
                    [
                      _c("b-link", { attrs: { to: "/webmag-login" } }, [
                        _vm._v(_vm._s(_vm.$t("login.login")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "b-card-forms" },
        [
          _c(
            "b-row",
            { staticClass: "my-4", attrs: { "align-h": "center", "p-0": "" } },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "align-self": "center",
                    cols: "12",
                    sm: "12",
                    lg: "12"
                  }
                },
                [
                  !_vm.loggedIn && !_vm.showThankYouText
                    ? _c(
                        "b-card",
                        [
                          _c(
                            "b-row",
                            { staticClass: "ml-0 mr-0" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "p-4 bg-white d-flex align-items-center",
                                  attrs: { cols: "12", sm: "12", lg: "6" }
                                },
                                [
                                  !_vm.userNotConfirmed
                                    ? _c(
                                        "div",
                                        { staticClass: "m-4" },
                                        [
                                          _c("h3", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("register.start")
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("h5", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("register.desc1")
                                                ) +
                                                " "
                                            )
                                          ]),
                                          _c("b-form-valid-feedback", [
                                            _c("div", { staticClass: "test" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "login.verificationCodeSendMessage"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ])
                                          ]),
                                          _c(
                                            "b-alert",
                                            {
                                              attrs: {
                                                show: _vm.userWasLoggedOut,
                                                variant: "warning"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "register.userLoggedOutMsg"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-form",
                                            {
                                              on: {
                                                submit: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.onSubmit.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "b-form-group",
                                                { attrs: { id: "name-group" } },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "name",
                                                      name: "name",
                                                      type: "text",
                                                      placeholder: _vm.$t(
                                                        "register.placeholderName"
                                                      ),
                                                      "aria-describedby":
                                                        "name-live-feedback",
                                                      state: _vm.validateState(
                                                        "name"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.form.name.$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v.form.name,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.form.name.$model"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    id: "telephone-group"
                                                  }
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "telephone",
                                                      name: "telephone",
                                                      type: "tel",
                                                      placeholder: _vm.$t(
                                                        "register.placeholderTelephone"
                                                      ),
                                                      "aria-describedby":
                                                        "telephone-live-feedback",
                                                      state: _vm.validateState(
                                                        "telephone"
                                                      )
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.form.telephone
                                                          .$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v.form.telephone,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.form.telephone.$model"
                                                    }
                                                  }),
                                                  _c(
                                                    "b-form-invalid-feedback",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "telephone-live-feedback"
                                                      }
                                                    },
                                                    [
                                                      !_vm.$v.form.telephone
                                                        .required
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.requiredTelephone"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.telephone
                                                        .numeric
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.invalidTelephone"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.telephone
                                                        .minLength
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.telephoneMinLength",
                                                                      {
                                                                        telephoneLength:
                                                                          _vm.$v
                                                                            .form
                                                                            .telephone
                                                                            .$params
                                                                            .minLength
                                                                            .min
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: { id: "email-group" }
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "email-input",
                                                      name: "email-input",
                                                      type: "email",
                                                      placeholder: _vm.$t(
                                                        "login.placeholderEmail"
                                                      ),
                                                      state: _vm.validateState(
                                                        "email"
                                                      ),
                                                      "aria-describedby":
                                                        "email-live-feedback",
                                                      disabled: _vm.isDisabled
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.resetDuplicateEmail
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.form.email
                                                          .$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v.form.email,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.form.email.$model"
                                                    }
                                                  }),
                                                  _c(
                                                    "b-form-invalid-feedback",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "email-live-feedback"
                                                      }
                                                    },
                                                    [
                                                      !_vm.$v.form.email.email
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "login.invalidEmail"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.email
                                                        .b2bEmailValidation
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.notBusinessEmail"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.duplicateEmail
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "error invalid-feedback d-block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "register.emailAlreadyExists"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass:
                                                    "position-relative",
                                                  attrs: {
                                                    id: "password-group"
                                                  }
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id: "password-input",
                                                      name: "password-input",
                                                      placeholder: _vm.$t(
                                                        "login.placeholderPassword"
                                                      ),
                                                      type: _vm.showPassword
                                                        ? "text"
                                                        : "password",
                                                      state: _vm.validateState(
                                                        "password"
                                                      ),
                                                      "aria-describedby":
                                                        "password-live-feedback"
                                                    },
                                                    on: {
                                                      input:
                                                        _vm.resetInvalidPassword
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.form.password
                                                          .$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v.form.password,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.form.password.$model"
                                                    }
                                                  }),
                                                  !_vm.showPassword
                                                    ? _c("b-icon", {
                                                        staticClass:
                                                          "position-absolute password-eye",
                                                        attrs: {
                                                          icon: "eye-slash",
                                                          "aria-hidden": "true",
                                                          scale: "1"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.togglePasswordVisibility(
                                                              true
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("b-icon", {
                                                        staticClass:
                                                          "position-absolute password-eye",
                                                        attrs: {
                                                          icon: "eye",
                                                          "aria-hidden": "true",
                                                          scale: "1"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.togglePasswordVisibility(
                                                              false
                                                            )
                                                          }
                                                        }
                                                      }),
                                                  _c(
                                                    "b-form-invalid-feedback",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "password-live-feedback"
                                                      }
                                                    },
                                                    [
                                                      !_vm.$v.form.password
                                                        .required
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.requiredPassword"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.password
                                                        .minLength
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.passwordMinLength",
                                                                      {
                                                                        pwLength:
                                                                          _vm.$v
                                                                            .form
                                                                            .password
                                                                            .$params
                                                                            .minLength
                                                                            .min
                                                                      }
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.password
                                                        .letterValidation
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.letterRequired"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.password
                                                        .numberValidation
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.numberRequired"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.$v.form.password
                                                        .specialCharValidation
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "error"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "register.specialCharRequired"
                                                                    )
                                                                  )
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  ),
                                                  _vm.invalidPassword
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "error invalid-feedback d-block"
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "register.invalidPasswordException"
                                                                )
                                                              )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass:
                                                    "position-relative",
                                                  attrs: {
                                                    id: "password-confirm-group"
                                                  }
                                                },
                                                [
                                                  _c("b-form-input", {
                                                    attrs: {
                                                      id:
                                                        "password-confirm-input",
                                                      name:
                                                        "password-confirm-input",
                                                      placeholder: _vm.$t(
                                                        "register.passwordConfirm"
                                                      ),
                                                      type: _vm.showConfirmPassword
                                                        ? "text"
                                                        : "password",
                                                      state: _vm.validateState(
                                                        "passwordConfirm"
                                                      ),
                                                      "aria-describedby":
                                                        "password-confirm-live-feedback"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.$v.form
                                                          .passwordConfirm
                                                          .$model,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.$v.form
                                                            .passwordConfirm,
                                                          "$model",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "$v.form.passwordConfirm.$model"
                                                    }
                                                  }),
                                                  !_vm.showConfirmPassword
                                                    ? _c("b-icon", {
                                                        staticClass:
                                                          "position-absolute password-eye",
                                                        attrs: {
                                                          icon: "eye-slash",
                                                          "aria-hidden": "true",
                                                          scale: "1"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.togglePasswordConfirmVisibility(
                                                              true
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("b-icon", {
                                                        staticClass:
                                                          "position-absolute password-eye",
                                                        attrs: {
                                                          icon: "eye",
                                                          "aria-hidden": "true",
                                                          scale: "1"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.togglePasswordConfirmVisibility(
                                                              false
                                                            )
                                                          }
                                                        }
                                                      }),
                                                  _c(
                                                    "b-form-invalid-feedback",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "password-confirm-live-feedback"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "register.passwordMismatch"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        id: "confirmation",
                                                        name: "confirmation",
                                                        value: "accepted",
                                                        "unchecked-value": null,
                                                        state: _vm.validateState(
                                                          "confirmation"
                                                        ),
                                                        "aria-describedby":
                                                          "confirmation-live-feedback"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.$v.form
                                                            .confirmation
                                                            .$model,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.$v.form
                                                              .confirmation,
                                                            "$model",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "$v.form.confirmation.$model"
                                                      }
                                                    },
                                                    [
                                                      _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            _vm.$t(
                                                              "register.confirmCheckbox"
                                                            )
                                                          )
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-invalid-feedback",
                                                    {
                                                      attrs: {
                                                        id:
                                                          "confirmation-live-feedback"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Please select two"
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "p-3",
                                                  attrs: {
                                                    block: "",
                                                    type: "submit",
                                                    variant: "success"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "register.button"
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-center mt-4"
                                                },
                                                [
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "password-reset"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "login.forgotPassword"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    [
                                                      _c(
                                                        "b-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              "/webmag-password-reset"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "login.resetPassword"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c("verification-code-msg", {
                                        staticClass: "m-4",
                                        attrs: { email: _vm.form.email },
                                        on: {
                                          "verification-success":
                                            _vm.verificationNumberEntered,
                                          "show-loader": _vm.showLoader
                                        }
                                      })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "imageColumn text-center flex-column p-4",
                                  attrs: { cols: "12", sm: "12", lg: "6" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "align-content-center" },
                                    [
                                      _c("img", {
                                        staticClass: "sigup m-auto rounded-0",
                                        attrs: {
                                          src: require("@/assets/webmag-signup.png"),
                                          alt: "Signup"
                                        }
                                      }),
                                      _c("h4", { staticClass: "mt-4 mb-3" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("register.desc2")) +
                                            " "
                                        )
                                      ]),
                                      _c("b-row", [
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/iveco.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/businesses.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/asd.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/sparkasse.svg"),
                                            alt: "Signup"
                                          }
                                        })
                                      ]),
                                      _c("b-row", [
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/cnhi.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/prognos.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/haufe-akademie.svg"),
                                            alt: "Signup"
                                          }
                                        }),
                                        _c("img", {
                                          staticClass:
                                            "businesses m-auto rounded-0",
                                          attrs: {
                                            src: require("@/assets/svg/navos.svg"),
                                            alt: "Signup"
                                          }
                                        })
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c("b-card", { attrs: { "body-class": "text-center" } }, [
                        _c("img", {
                          staticClass: "webmag-logo-big",
                          attrs: {
                            src: require("@/assets/logo-webmag-big.png"),
                            alt: "webmag logo"
                          }
                        }),
                        _c("div", [
                          _c("h5", { staticClass: "mb-4" }, [
                            _vm._v(_vm._s(_vm.$t("register.thankYouTitle")))
                          ]),
                          _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.$t("register.thankYouMessage")
                              )
                            }
                          }),
                          _c("p", [
                            _c("strong", [
                              _vm._v(_vm._s(_vm.$t("register.team")))
                            ])
                          ])
                        ])
                      ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }