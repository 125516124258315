const nonBusinessEmailBlacklist = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'yahoo.co.in',
  'aol.com',
  'live.com',
  'outlook.com',
  'web.de',
  'gmx.de',
  'gmx.net',
  'me.com',
  'icloud.com',
  'mail.com',
  'yandex.com',
  'yandex.ru',
  'inbox.com',
  'rediffmail.com',
  'hotmail.co.uk',
  'hotmail.fr',
  'msn.com',
  'yahoo.co.uk',
  'yahoo.com.br',
  'yahoo.fr',
  'yahoo.it',
  'laposte.net',
  'orange.fr',
  'comcast.net',
  'yahoo.co.jp',
  'sbcglobal.net',
  'ymail.com',
  'rocketmail.com',
  'att.net',
  'bellsouth.net',
  'verizon.net',
  'earthlink.net',
  'cox.net',
  'btinternet.com',
  'virgilio.it',
  'libero.it',
  'wanadoo.fr',
  'sky.com',
  'ntlworld.com',
];
export default nonBusinessEmailBlacklist;
